import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const getValidationState = ({ dirty, validated, required: fieldRequired, changed, valid = null }) => {
  const result = dirty || validated ? valid : null
  return !fieldRequired && !changed ? null : result
}

Vue.prototype.$getValidationState = getValidationState
