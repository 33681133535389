import Vue from 'vue'

Vue.prototype.$wrapWords = (html, maxLength) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const plainText = doc.body.textContent

  if (plainText.length <= maxLength) {
    return plainText
  }

  let wrappedText = ''
  let currentLine = ''
  let currentLineLength = 0

  for (let i = 0; i < plainText.length; i++) {
    const char = plainText[i]
    currentLine += char
    currentLineLength++

    if (currentLineLength === maxLength) {
      wrappedText += `${currentLine }\n`
      currentLine = ''
      currentLineLength = 0
    } else if (currentLineLength > maxLength) {
      wrappedText += `\n${ char}`
      currentLine = char
      currentLineLength = 1
    }
  }

  return `${wrappedText.trim()} ...`
}
