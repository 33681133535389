import Vue from 'vue'

Vue.prototype.$keypressNumber = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$keypressNumberNoDecimal = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$keypressTelInput = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) && charCode !== 43) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.rules = {
  required: (v) => !!v || 'กรุณากรอกช่องนี้',
  email: (v) => /.+@.+\..+/.test(v) || 'กรุณากรอกอีเมล'
}
