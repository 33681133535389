import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/email-group',
    name: 'email-group',
    component: () => import('@/views/Email-Group/EmailGroup.vue'),
    meta: {
      title: 'Email Group',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  },
  {
    path: '/email-group/add',
    name: 'email-group-add',
    component: () => import('@/views/Email-Group/components/EmailGroupAdd.vue'),
    meta: {
      title: 'Email Group Add',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  },
  {
    path: '/email-group/:id',
    name: 'email-group-detail',
    component: () => import('@/views/Email-Group/components/EmailGroupUpdate.vue'),
    meta: {
      title: 'Email Group Update',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  }

]
