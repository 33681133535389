import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/user-group',
    name: 'user-group',
    component: () => import('@/views/Users/Group.vue'),
    meta: {
      title: 'Group',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/user-team',
    name: 'user-team',
    component: () => import('@/views/Users/Team.vue'),
    meta: {
      title: 'Team',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/user',
    name: 'user-user',
    component: () => import('@/views/Users/User.vue'),
    meta: {
      title: 'User',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  }
]
