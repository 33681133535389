import Vue from 'vue'

Vue.prototype.$replacePhoneNumber = (phoneNumber, countryCode) => {
  const prefixes = {
    '+66': '0', // Example: Replace "0" with "+66" for Thailand
    '+1': '1' // Example: Replace "1" with "+1" for United States
    // Add more country codes and corresponding replacement prefixes here
  }

  const prefix = prefixes[countryCode]

  if (prefix && phoneNumber && phoneNumber?.startsWith(prefix)) {
    return phoneNumber?.replace(prefix, countryCode)
  }

  return phoneNumber || ''
}
