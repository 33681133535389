/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import activity from './activity'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'verticalMenu', 'appConfig', 'user']
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    activity
  },
  plugins: [vuexLocal.plugin],
  strict: process.env.DEV
})
