import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/ticket/contact',
    name: 'ticket-contact',
    component: () => import('@/views/Tickets/Contact.vue'),
    meta: {
      title: 'Contact',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/ticket/type',
    name: 'ticket-type',
    component: () => import('@/views/Tickets/Type.vue'),
    meta: {
      title: 'Type',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/plant',
    name: 'plant',
    component: () => import('@/views/Tickets/Plant.vue'),
    meta: {
      title: 'Plant',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  }
]
