import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// Routes
import { isValidToken } from '../auth/utils'
import users from './routes/users'
import categories from './routes/categories'
import customers from './routes/customers'
import tickets from './routes/tickets'
import others from './routes/others'
import skillName from './routes/skill-name'
import knowledgeType from './routes/knowledge-type'
import knowledgeCategoriesMapping from './routes/knowledge-categories-mapping'
import emailGroup from './routes/email-group'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/user-group'
    },
    ...knowledgeCategoriesMapping,
    ...knowledgeType,
    ...users,
    ...categories,
    ...customers,
    ...tickets,
    ...others,
    ...skillName,
    ...emailGroup,
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
        auth: ['all']
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        auth: ['all']
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

const publicRoute = ['login']
// const superAdminRoute = ['ClientList', 'AdminList', 'AdminAdd']
// const nonSuperAdminRoute = ['ReportHome']

router.beforeEach((to, from, next) => {
  const routeName = to.name

  // const role = getRole()
  const user = store.getters['user/user']

  if (publicRoute.some((pr) => pr === routeName)) {
    return next()
  }
  // const token = getAccessToken()
  const tokenValid = isValidToken()

  if (!tokenValid) {
    store.dispatch('user/resetUser')
    return next({ name: 'login' })
  }

  if (to?.meta.auth?.includes(user?.role) || to?.meta.auth?.includes('all')) {
    return next()
  }

  return next({ name: 'other-knowledge' })
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
