import Vue from 'vue'

Vue.prototype.$categoryValue = (status) => {
  switch (status) {
  case 'low':
    return 'light-success'
  case 'medium':
    return 'light-warning'
  case 'high':
    return 'light-danger'
  default:
    return ''
  }
}
