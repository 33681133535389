import Vue from 'vue'

Vue.filter('formatStatusData', (val) => {
  switch (val) {
  case 'active':
    return 'Active'
  case 'inactive':
    return 'Inactive'
  case 'deleted':
    return 'Deleted'
  default:
    return ''
  }
})
