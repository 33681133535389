import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/other/knowledge',
    name: 'other-knowledge',
    component: () => import('@/views/Others/Knowledge.vue'),
    meta: {
      title: 'Knowledge',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  },
  {
    path: '/other/knowledge/add',
    name: 'other-knowledge-add',
    component: () => import('@/views/Others/knowledge/KnowledgeAdd.vue'),
    meta: {
      title: 'Knowledge Add',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  },
  {
    path: '/other/knowledge/:id',
    name: 'other-knowledge-updates',
    component: () => import('@/views/Others/knowledge/KnowledgeUpdate.vue'),
    meta: {
      title: 'Knowledge Update',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  },
  {
    path: '/other/working',
    name: 'other-working',
    component: () => import('@/views/Others/Working.vue'),
    meta: {
      title: 'Holidays',
      auth: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGEMENT, RoleEnum.MANAGER, RoleEnum.SUPERVISOR]
    }
  }
]
