import Vue from 'vue'

Vue.filter('formatCategoryData', (val) => {
  switch (val) {
  case 'low':
    return 'Low'
  case 'medium':
    return 'Medium'
  case 'high':
    return 'High'
  default:
    return ''
  }
})
