/* eslint-disable import/no-cycle */
import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  login (payload) {
    return this.post('/auth/login', payload)
  }
}

export default AuthProvider
