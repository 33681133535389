import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/customer-group',
    name: 'customer-group',
    component: () => import('@/views/Customers/Group.vue'),
    meta: {
      title: 'Customer Group',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/customer-type',
    name: 'customer-type',
    component: () => import('@/views/Customers/Type.vue'),
    meta: {
      title: 'Customer Type',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/company-department',
    name: 'company-department',
    component: () => import('@/views/Customers/CompanyDepartment.vue'),
    meta: {
      title: 'Company Department',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/company-group',
    name: 'company-group',
    component: () => import('@/views/Customers/CompanyGroup.vue'),
    meta: {
      title: 'Company Group',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  },
  {
    path: '/company-center',
    name: 'company-center',
    component: () => import('@/views/Customers/CompanyCenter.vue'),
    meta: {
      title: 'Company Center',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  }
]
