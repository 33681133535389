import Vue from 'vue'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/th'
import 'dayjs/locale/en'

dayjs.extend(buddhistEra)
dayjs.extend(utc)

Vue.prototype.$dayjs = dayjs

Vue.filter('formatMiniDate', (val) => dayjs(val).locale('th').format('DD/MM/BBBB'))

Vue.filter('formatDate', (val) => dayjs(val).locale('th').format('DD MMMM BBBB HH:mm:ss'))

Vue.filter('formatDateTime', (val) => dayjs(val).locale('th').format('DD MMMM BBBB HH:mm'))

Vue.filter('formatDateEn', (val) => dayjs(val).locale('en').format('DD MMM, YYYY'))

Vue.filter('formatDateTimeEn', (val) => dayjs(val).locale('en').format('DD MMMM YYYY HH:mm'))

Vue.filter('formatTime', (val) => dayjs(val).format('HH:mm'))

Vue.filter('formatAge', (val) => dayjs(new Date()).diff(dayjs(val), 'year'))

Vue.filter('checkExpired', (val) => dayjs(new Date()).diff(dayjs(val), 'm'))

Vue.filter('formatDayOfWeek', (val) => {
  const weeks = val.map((day) => dayjs().day(day).format('ddd'))

  return `${weeks[0]} - ${weeks[weeks.length - 1]}`
})
