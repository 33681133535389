import Vue from 'vue'

const isHaveArray = (val) => {
  if (val && val.length > 0) {
    return true
  }
  return false
}

Vue.prototype.$checkRole = (role, userRole) => {
  if (!isHaveArray(role) || !isHaveArray(userRole)) {
    return false
  }

  if (role.includes(userRole)) {
    return true
  }

  return false
}

Vue.prototype.$checkRoleAndGroup = (role, userRole, group, userGroup) => {
  if ((!isHaveArray(role) || !isHaveArray(userRole))
  && (!isHaveArray(group) || !isHaveArray(userGroup))) {
    return false
  }

  if (role.includes(userRole) && group.includes(userGroup)) {
    return true
  }

  return false
}
