import RoleEnum from '@/common/role.enum'

export default [
  {
    path: '/knowledge-categories-mapping',
    name: 'knowledge-categories-mapping',
    component: () => import('@/views/Knowledge-Categories-Mapping/index.vue'),
    meta: {
      title: 'Knowledge Categories Mapping',
      auth: [RoleEnum.SUPER_ADMIN]
    }
  }
]
